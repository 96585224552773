import { Link, useLocation } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { Flex, Text } from "@chakra-ui/react";
import { useSettings } from "hooks/settings";
import { PlanType } from "enum/planTypes";
import { useSubscription, useUser } from "hooks/user";
import { useEffect, useState } from "react";
import { ROLES } from "enum/roles";
import { useGetManualInvoices } from "hooks/billing";
import { InvoiceStatus } from "enum/invoiceStatus";

import "./news-letter.scss";
import { useGetAllNotifications } from "hooks/notifications";
import { INotificationResponse } from "types/services";
import { CustomNotificationLocation } from "enum/notifications";
import { Routes } from "constants/routes";
import { REACT_APP_FE_URL } from "constants/constant";

const NewsStrip = () => {
  const { user } = useUser();
  const { subscription, isGettingSubscription } = useSubscription();
  const { settings } = useSettings();
  const location = useLocation();
  
  const isBetaMode =
    settings?.find?.((item) => item?.settingKey === "beta-version")
      ?.settingValue === "ON";

  const [showNewsStrip, setShowNewsStrip] = useState(false);
  const [subscriptionIsInReview, setSubscriptionIsInReview] = useState(false);
  const { manualInvoices, isLoading: loadingManualInvoices } = useGetManualInvoices();
  const { notifications } = useGetAllNotifications({ page: 1, limit: 100 });
  const subscriptionRemainingDays = +subscription?.remainingDays! + 1;
  const [actveNotification, setActiveNotification] = useState<INotificationResponse>();
  const [isPublicPagesRoute, setIsPublicRoute] = useState(!location.pathname.startsWith("/user/") && !location.pathname.startsWith("/admin/"));

  useEffect(() => {
    setIsPublicRoute(!location.pathname.startsWith("/user/") && !location.pathname.startsWith("/admin/"));
  }, [location.pathname])

  useEffect(() => {
    if (isPublicPagesRoute && !!notifications?.length) {
      setActiveNotification(
        notifications.find((notification: INotificationResponse) =>
          !!notification?.status &&
          (
            !!notification?.location?.includes(CustomNotificationLocation.PUBLIC_PAGES) ||
            notification?.location?.some(notifilocation => location.pathname.startsWith(notifilocation))
          )
        )
      );

      return;
    }

    if (!!notifications?.length) {
      setActiveNotification(
        notifications.find((notification: INotificationResponse) =>
          !!notification?.status &&
          (
            !!notification?.location?.includes(CustomNotificationLocation.USER_DASHBOARD ) ||
            notification?.location?.some(notifilocation => location.pathname.startsWith(notifilocation))
          )
        )
      );
    }
  }, [notifications, notifications?.length, location.pathname, isPublicPagesRoute]);
  

  useEffect(() => {
    setShowNewsStrip(user?.role !== ROLES?.SUPER_ADMIN && !isBetaMode && !isGettingSubscription && (!subscription?.status || subscription?.planType === PlanType.FREE || !user?.storeSearches || !user?.listingSearches || !user?.marketSearches))
  }, [user?.role, isBetaMode, subscription?.status, subscription?.planType, user?.listingSearches, user?.marketSearches, user?.storeSearches, isGettingSubscription]);


  useEffect(() => {
    if (!loadingManualInvoices && manualInvoices?.length && manualInvoices[0]?.status === InvoiceStatus.IN_REVIEW) {
      setSubscriptionIsInReview(true)
    }
  }, [loadingManualInvoices, manualInvoices]);


  if (actveNotification?.id) {
    return <Flex
      className="news-strip"
      style={{ backgroundColor: actveNotification?.backgroundColor, ...(isPublicPagesRoute && {position: "fixed", zIndex: 999}) }}>
      <Text className="text" dangerouslySetInnerHTML={{ __html: actveNotification?.text }} />
      {
        actveNotification?.buttonText && actveNotification?.buttonLink && location?.pathname !== actveNotification?.buttonLink ?
          <Flex className="news-btn">
            <Link
              to={`${actveNotification.buttonLink}`}
              className="btn-text"
              target="_blank"
            >
             {actveNotification.buttonText}
            </Link>
          </Flex>
          : <></>
      }
    </Flex>
  }

  if (!showNewsStrip || location.pathname === Routes.HOME) return <></>;

  return (
    <Flex className="news-strip" style={{ backgroundColor: subscription?.planType === PlanType.FREE && !subscriptionIsInReview ? "#185ADB" : subscriptionRemainingDays > 2 || subscriptionIsInReview ? "rgb(255 125 25)" : "#ff4c49" }}>
      {subscription?.planType === PlanType.FREE && !subscriptionIsInReview ? (
        <>
          <Text className="text">
            Upgrade today and get access to all exclusive features
          </Text>
          {
            location?.pathname !== "/user/billing" && !subscriptionIsInReview ?
            <Flex className="news-btn">
              <Link
                to={`${REACT_APP_FE_URL}/user/billing`}
                className="btn-text"
              >
                Try it Now
              </Link>
            </Flex>
            :<></>
          }
        </>
      ) : (
            <>
              <Text className="text">
                {!subscription?.status && !subscriptionIsInReview
                ? (
                  subscriptionRemainingDays > 0 ?
                    `Your ${subscription?.period === 7 ? "free trial" :"subscription" } will expire after ${subscriptionRemainingDays} ${subscriptionRemainingDays > 1 ? "days" : "day"}`
                    : `Your  ${subscription?.period === 7 ? "7 days free trial" :"subscription" } has been expired`
                )
                : subscriptionIsInReview ? "Your subscription request is IN REVIEW" : "It seems you are approaching the limit of your search quota"
              }
              </Text>
              {
                location?.pathname !== "/user/billing" && !subscriptionIsInReview
                ? <Flex className="news-btn">
                  <Link
                    to={`${REACT_APP_FE_URL}/user/billing`}
                    className="btn-text"
                  >
                   {subscription?.period === 7 ? "Purchase Now" :"Renew Now" }
                  </Link>
                </Flex>
                : <></>
              }
            </>
      )}
      <Flex>
        <IoMdClose
          className="close-icon"
          onClick={() => { setShowNewsStrip(false) }}
        />
      </Flex>
    </Flex>
  );
};

export default NewsStrip;
