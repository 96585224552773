export enum Apis {
  RESEND_CODE = "users/resendCode",
  REFRESH = "users/refresh",
  SIGNIN = "users/signin",
  SIGNUP = "users/signup",
  GOOGLE_LOGIN = "users/googleLogin",
  LOGOUT = "users/logout",
  VERIFY = "users/verify",
  FORGOT = "users/forgotPassword",
  UPDATE_PASSWORD = "users/updatePassword",
  UPDATE_PASSWORD_PROFILE = "users/profile/updatePassword",
  UPDATE_USER = "users/updateMyAccount",
  GET_PLANS = "plans",
  GET_PAYMENT_METHODS = "payment-methods",
  GET_COLLECTIONS = "collections/myCollections",
  CREATE_COLLECTION = "collections/create",
  GET_PRODUCTS = "products/getProducts",
  SEARCH_PRODUCT_DISCOVERY = "products/search-products",
  PRODUCT_DISCOVERY_DETAILS = "products/product-details",
  GET_DASHBOARD_PRODUCTS = "users/latest-products",
  GET_FAVOURTITE_PRODCUTS = 'collectionProducts/checkInCollections',
  COMISSIONS = "comissions",
  ADD_PRODUCTS_TO_COLLECTION = "collectionProducts/addProduct",
  REMOVE_PRODUCT_FROM_COLLECTION="collectionProducts/removeProduct",
  GET_STORES = "stores/getStores",
  SEARCH_STORE_DISCOVERY = "stores/search-stores",
  STORE_DISCOVERY_DETAILS = "stores/store-details",
  GET_COLLECTION_BY_ID = "collectionProducts/getCollectionProducts",
  DELETE_COLLECTION = "collections/deleteCollection",
  UPDATE_COLLECTION = "collections/updateCollection",
  MANUAL_PAYMENT = "manualPayments/uploadInfo",
  GET_MY_SUBSCRIPTION = "subscriptions/mySubscription",
  GET_MANUAL_INVOICE = "invoices",
  GENERATE_INVOICE = "invoices/generate",
  GET_STRIPE_INVOICE = "invoices/getStripeInvoices",
  EXTENSION_INSTALLED_STATUS = "users/extensionInstalled",
  EXTENSION_UNINSTALL_FEEDBACK = "extension/uninstall-feedback",
  // Admin Apis
  GET_DASBOARD_COUNTS = "admin/getDashboardCounts",
  GET_ALL_USERS ="admin/allUsers",
  UPDATE_ADMIN_USER = "users/updateUser",
  GET_ALL_SUBSCRIPTIONS = "subscriptions/getAllSubscriptions",
  UPDATE_SUBSCRIPTION = "subscriptions/updateSubscription",
  AVAIL_FREE_SUBSCRIPTOIN = "subscriptions/freeTrial",
  DOWNGRADE_TO_FREE_PLAN = "subscriptions/downgrade-free",
  CANCEL_SUBSCRIPTION = "subscriptions/cancel",
  GET_ALL_INVOICES = "admin/getAllInvoices",
  CREATE_COUPON = "coupons/create",
  UPDATE_COUPON = "coupons/update",
  UPDATE_ROLE = "roles/updateRole",
  CREATE_ROLE = "roles/createRole",
  CREATE_USER = "users/createUser",
  GET_PERMISSION ="permissions/",
  GET_BLOG_CATEGORIES = "blogs/categories",
  CREATE_BLOG_CATEGORY = "blogs/category",
  DELETE_BLOG_CATEGORY = "blogs/category",
  UPDATE_BLOG_CATEGORY = "blogs/category",
  CREATE_BLOG="blogs/",
  DELETE_BLOG = "blogs/",
  UPDATE_BLOG = "blogs/",
  SUBSCRIBE_NEWSLETTER = "newsletters/subscribe",
  CONTACT_US = "users/contact-us",
  GET_SETTINGS = "site-settings/extension-settings",
  UPDATE_INVOICE_STATUS = "invoices/",
  NOTIFICATION = "notifications/",
  SEND_NEWSLETTER = "newsletters/send-news-letter",
  GET_FAQS = "faqs/",
  CREATE_FAQS = "faqs/",
  UPDATE_FAQS = "faqs/",
  DELETE_FAQS = "faqs/",
  SEND_WHATSAPP_OTP="users/send-whatsapp-otp",
  VALIDATE_WHATSAPP_OTP="users/validate-whatsapp-otp",
  CREATE_REVIEWS = 'user_reviews',
  UPDATE_REVIEWS='user_reviews',
  DELETE_REVIEWS='user_reviews',
  GET_REVIEWA='user_reviews',
  CREATE_REWARD='rewards',
  UPDATE_REWARD='rewards',
  DELETE_REWARD='rewards',
  GET_SOCIAL_VIDEOS = "social-videos/",
  GET_ACTIVE_SOCIAL_VIDEOS = "social-videos/active",
  CREATE_SOCIAL_VIDEOS = "social-videos/",
  UPDATE_SOCIAL_VIDEOS = "social-videos/",
  DELETE_SOCIAL_VIDEOS = "social-videos/",
}
