export const REACT_APP_GOOGLE_CLIENT_ID="408141771858-qot26mkpk0etedaikslo6puaa0dbb553.apps.googleusercontent.com"
export const REACT_APP_BACKEND="https://backend.sellerdash.pk/api/"
export const REACT_APP_EXTENSION_ID="cadnljldbdgkkecmonojainnjkbfaamm"
export const REACT_APP_FE_URL="https://sellerdash.pk"
export const REACT_APP_YT_VIDEO_ID="a57fIEeK_RY"
export const REACT_APP_COOKIES_PASSWORD_KEY="HQLIvyIhZMV8ZJYiM2IA7UJhVdJYN81jINlU"

export const REACT_APP_PRESET_KEY="gxt4dhhp"
export const REACT_APP_CLOUD_NAME="dbnvrad26"
export const REACT_APP_ENCRYPTION_KEY="1a2b3c4d5e6f7a8b9c0d1e2f3a4b5c6d"
export const REACT_APP_ENCRYPTION_IV="1234567890abcdef1234567890abcdef"

export const REACT_APP_GOOGLE_TAG_ID="GTM-NVLHSBFJ"

export const REACT_APP_GOOGLE_SITE_KEY="6LfzhhsqAAAAAEliN_1FdhxKsuII6ejr_5mXpDVl"

export const REACT_APP_META_PIXEL_ID="574924361902258"

export const DARAZ_API_SECRET=""